@import 'variables';

#content-wrapper {
    margin-top: 30px;
    padding-bottom: 20px;
}

/* Copy styles from .alert-danger */
.alert-error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

/* When we use button styles on links, make sure we don't use the orange link color */
a.btn {
    color: white;
}
a.btn.btn-default {
    color: black;
}

a.disabled, .dropdown-menu>li>a.disabled {
  color: gray;
  pointer-events: none;
}

/* The bootstrap theme has way too much padding by default */
h1 {
    margin-top: 0;
}
// Default fonts are huge
h1 {
    font-size: 26px;
}
h2 {
    font-size: 22px;
}
h3 {
    font-size: 18px;
}

.label-orange {
    background-color: $primary-color;
}

// Use the orange for the active list element in a group
.list-group-item.active, .list-group-item.active:hover {
    background-color: $primary-color;
    border-color: $primary-color;
}

// If we're using orange buttons in an orange panel heading, make them white bg / orange text
.panel-orange .panel-heading .btn-orange {
    background-color: white;
    color: $primary-color !important; // color-styles.css also uses !important so we have to
}

// For bootstrap-switch so we have the primary orange option
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-orange,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-orange {
  color: #fff;
  background: $primary-color;
}

/* Nav */
#sh-app-nav {
    background-color: black;

    // Get rid of the border radius
    border-radius: 0;

    // Consistent size even w/o menu (like on login page)
    min-height: 75px;

    // Make sure we dont have white-on-white on hover
    .dropdown-menu a:hover {
        color: $primary-color;
    }

    #profile-nav-dropdown {
        padding: 20px;
    }

    .profile-name {
        color: $primary-color;
        font-weight: bold;
        padding: 7px 20px;
    }

    .badge {
        background-color: $primary-color;
    }
}

h1 small, h2 small {
    &.extra-small {
        font-size: 40%;
    }
}

th input {
  // If we have some inputs in a header, don't bold them, it looks weird
  font-weight: normal;
}

// Customize the toast messages a big
#toast-container {
    &.toast-top-right {
        top: 80px;
    }

    div {
        opacity: .9;
    }
}

.action-bar {
    // These are when we have some buttons on the right of the header, and we'll
    // typically have them wrap on mobile, so give some padding.
    padding-bottom: 5px;
}

.type-label {
    text-transform: uppercase;
    color: #999999;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}

// Bootstrap v3.3.6 (seems all of 3.X) doesn't seem to work as well with newer browser-based time pickers
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: inherit;
  }
  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm,
  .input-group-sm input[type="date"],
  .input-group-sm input[type="time"],
  .input-group-sm input[type="datetime-local"],
  .input-group-sm input[type="month"] {
    line-height: inherit;
  }
  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg,
  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"] {
    line-height: inherit;
  }
}


/******************************************************************************
* Handy styles for alignment only when in certain sizes, e.g:
* "align right when we're showing cols side by side, but not if they're stacked"
* https://stackoverflow.com/a/25536523/135108
******************************************************************************/

.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {
    text-align: left;
}
.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg {
    text-align: center;
}
.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg {
    text-align: right;
}
.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg {
    text-align: justify;
}

@media (max-width: 767px) {
    .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs {
        text-align: inherit;
    }
    .text-left-xs {
        text-align: left;
    }
    .text-center-xs {
        text-align: center;
    }
    .text-right-xs {
        text-align: right;
    }
    .text-justify-xs {
        text-align: justify;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm {
        text-align: inherit;
    }
    .text-left-sm {
        text-align: left;
    }
    .text-center-sm {
        text-align: center;
    }
    .text-right-sm {
        text-align: right;
    }
    .text-justify-sm {
        text-align: justify;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md {
        text-align: inherit;
    }
    .text-left-md {
        text-align: left;
    }
    .text-center-md {
        text-align: center;
    }
    .text-right-md {
        text-align: right;
    }
    .text-justify-md {
        text-align: justify;
    }
}
@media (min-width: 1200px) {
    .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg {
        text-align: inherit;
    }
    .text-left-lg {
        text-align: left;
    }
    .text-center-lg {
        text-align: center;
    }
    .text-right-lg {
        text-align: right;
    }
    .text-justify-lg {
        text-align: justify;
    }
}

/******************************************************************************
* Mixins
******************************************************************************/

%progress {
    // For things such as displaying goal progress
    .progress {
        margin-bottom: 0;
    }
    .progress-bar {
        background-color: $primary-color;
    }
}


/******************************************************************************
* Login / registration
******************************************************************************/
.registration {
    .login-3rd-party {
        margin-top: 30px;

        hr {
            border: 0;
            height: 1px;
            background: #CCC;
        }
        .login-alt {
            margin-top: -30px;
            margin-left: 30%;
            text-align: center;
            background-color: #f5f5f5;
            width: 100px;
        }
        .login-3rd-party-vendor {
            margin: 20px 0;

            i {
                padding-right: 5px;
            }
            a {
                width: 100%;
                text-align: left;
                padding: 10px;
            }
        }
    }

    // On the Registration page, we're showing the buttons in an alert area, so we can reduce some padding
    .alert {
        .login-3rd-party {
            margin: 0;

            label {
                margin-top: 10px;
            }
        }
        .login-3rd-party-vendor {
            margin-bottom: 0;
        }
    }
}

/******************************************************************************
* Content pages
******************************************************************************/
body.content-page {

    .navbar-brand img {
        padding-left: 50px;
        padding-top: 10px;
        max-width: 240px;
    }

    .header {
        background-image: url('../img/content/content-header-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        height: 410px;
    }

    #sh-app-nav, #sh-app-nav-collapse, .navbar-dark .navbar-collapse {
        background: none;

        a {
            font-size: 16px;
            font-weight: bold;
        }
    }

    .page-title {
        margin-top: 115px;
        text-align: center;
        color: white;
        text-transform: uppercase;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-size: 70px;
        font-weight: 600;
    }

    @media (max-width: 767px) {
        .navbar-brand img {
            max-width: 200px;
        }
        .page-title {
            font-size: 40px;
        }
    }
}


/******************************************************************************
* Team management
******************************************************************************/
.team-list .team {
    border: 1px solid #dddddd;
    padding: 5px;
}

.edit-player-roster-details div.controls {
    padding-top: 6px;
}

.player-lookup {
    .player {
        padding: 5px;
        font-size: 18px;
    }
    .player.odd {
        background-color: #f7f7f7;
    }
    .player.even {

    }
    .player .headshot {
        width: 64px;
        margin-right: 10px;
    }
    .player .invite {
        margin-top: 12px;
    }
    .player .no-email {
        margin-top: 12px;
        font-style: italic;
    }

}

.team-admin {
    .dropdown {
        small {
            font-size: 10px;
            font-style: italic;
            padding-left: 10px;
        }
    }
}

.roster {
    .drill-actions {
        a, button {
            margin-top: 5px;
        }
    }

    .player-roster {
        .panel-heading {
            font-weight: bold;
        }

        .player-info {
            padding-left: 15px;
            width: 110px;
            float: left;
        }

        .player-details {
            padding-right: 15px;
            margin-left: 125px;
        }

        .check-holder {
            width: 20px;
            display: inline-block;
        }

        .jersey-holder {
            width: 30px;
            display: inline-block;
        }

        .last-session {
            font-size: 12px;
            color: #888888;
            padding-right: 10px;
        }
    }
}

/******************************************************************************
* Stats
******************************************************************************/

body.stats {
    .player-info {
        margin-bottom: 5px;
    }

    h1 {
        font-size: 24px;
        margin-bottom: 0;
    }
    .player-username {
        font-size: 16px;
    }
}

#shot_chart {
    text-align: center;
}

// This only works on phones, otherwise the layout is screwy for larger displays
@media (max-width: 768px) {
    #chart-row .chart-col {
        // Remove the typical .row padding so the chart can be a bit wider, and seems to fix the responsive positioning
        padding: 0;
    }
}

#shot_chart2 {
    img {
        position: absolute;
        top: 0;
        // padding-right: 30px;
    }
    img#chart2-base {
        // The first image (wooden court) should be relative so it lets the outer div get a size
        position: relative;
        // padding-right: 0;
    }

    .chart2-tooltip {
        position: absolute;
        text-align: center;
        text-shadow: 1px 1px 2px #444;
        color: white;
        z-index: 20;

        width: 100px;
        // Tweak the width for phones
        @media (max-width: 768px) { width: 85px; }

        .chart2-shots {
            display: block;
            font-size: 13px;
        }
        .chart2-pct {
            display: block;
            font-size: 16px;
            font-weight: bold;
        }

        &.chart2-section0  { top: 37%; left:  0%;   }
        &.chart2-section1  { top: 10%; left:  6.5%;  }
        &.chart2-section2  { top: 17%; left:  21%;   }
        &.chart2-section3  { top: 12%; left:  38.5%; }
        &.chart2-section4  { top: 17%; right: 23.5%; }
        &.chart2-section5  { top: 10%; right: 8.5%;  }
        &.chart2-section6  { top: 37%; right: 2%;    }

        &.chart2-section7  { top: 27%; left:  38.5%; }

        &.chart2-section8  { top: 47%; left:  18%;   }
        &.chart2-section9  { top: 57%; left:  38.5%; }
        &.chart2-section10 { top: 47%; right: 18%;   }

        &.chart2-section11 { top: 74%; left:  6%;    }
        &.chart2-section12 { top: 83%; left:  38.5%; }
        &.chart2-section13 { top: 74%; right: 8%;    }

        // For phones, things are shifted a bit so adjust
        @media (max-width: 768px) {
            &.chart2-section0  { top: 34%; left:  -2%;   }
            &.chart2-section1  { top: 10%; left:  4%;  }
            &.chart2-section2  { top: 17%; left:  20%;   }
            &.chart2-section3  { top: 10%; left:  38.5%; }
            &.chart2-section4  { top: 17%; right: 20%; }
            &.chart2-section5  { top: 10%; right: 4%;  }
            &.chart2-section6  { top: 34%; right: -2%;    }
    
            &.chart2-section7  { top: 27%; left:  38.5%; }
    
            &.chart2-section8  { top: 47%; left:  16%;   }
            &.chart2-section9  { top: 57%; left:  38.5%; }
            &.chart2-section10 { top: 47%; right: 16%;   }
    
            &.chart2-section11 { top: 74%; left:  3%;    }
            &.chart2-section12 { top: 81%; left:  38.5%; }
            &.chart2-section13 { top: 74%; right: 3%;    }
        }
    }
}

// Hovering tooltip while mousing/touching over shots on the chart
.shot-tooltip {
    position: absolute;
    display: none;
    min-width: 120px;
    height: auto;
    background-color: $primary-color;
    border: 1px solid black;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: white;
    padding: 5px;
    text-align: center;
    font-weight: bold;
}
.shot-tooltip:after { // Triangle pointing south to be stuck at the bottom of the tooltip
    box-sizing: border-box;
    display: inline;
    font-size: 14px;
    width: 100%;
    line-height: 1;
    color: $primary-color;
    position: absolute;
    pointer-events: none;
    content: "\25BC";
    margin: -3px 0 0 0;
    top: 100%;
    left: 0;
    text-align: center;
}

.shot-summary {
    div.summary-header-text {
    }
    .summary-panel {
        margin-bottom: 10px;
    }
    .summary-box {
        cursor: pointer;
    }
    .summary-active {
        border-bottom: 4px solid $primary-color;
        border-top: 4px solid $primary-color;
    }
    .shot-progress-header {
        font-weight: bold;
        color: #666;
    }
    .progress {
        margin-bottom: 10px;
    }
    .progress-bar.shot-progress {
        background-color: $primary-color;
    }
}

.session-list-panel {
    .pagination {
        margin: 10px;
    }

    .unread {
        color: $comment-unread-color;
    }
}

#line_chart {
    path.shotline {
        stroke: $primary-color;
        stroke-width: 2;
        fill: none;
    }

    .axis path,
    .axis line {
        fill: none;
        stroke: white;
        stroke-width: 1;
        shape-rendering: crispEdges;
    }
    .axis text {
        fill: white;
    }

    .dot {
        cursor: pointer;
        fill: $primary-color;

        &.hover {
            fill: white;
        }
    }

    .labeltooltip {
        fill: white;
        text-anchor: middle;
    }
}

.session-details {
    .progress {
        margin-bottom: 0;
    }
    .progress-bar.shot-progress {
        background-color: transparentize($primary-color, .25);
    }
}

#session-shots {
    #shot-dots {
        margin-left: 15px;
    }
}

body.session-details {
    #comment-container {
        blockquote {
            font-size: 14px;
        }
    }

    .shot {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin: 3px;
        color: white;
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
        padding-top: 4px;

        &.make {
            background: #0bcc00;
        }
        &.miss {
            background: #c70000;
        }
        &.swish {
            background: #0bcc00;
            border-radius: 16px;
        }
        &.shot-video {
            cursor: pointer;
        }
        &.faded {
            opacity: .25;
        }

        i.read {
            color: white;
        }
        i.unread {
            color: $comment-unread-color;
        }
    }

    #annotation-text { // Caption under video to show comment text with annotations
        display: none;
    }
    // Holds the SVG we're rendering on top of the video
    #annotation-holder {
        display: none;
        //z-index: 100000;
        position: relative;
    }

    .share-action {
        padding: 5px 0;

        button, a.btn {
            width: 175px;
        }
        span {
            color: #888888;
        }
    }
}

#shot-video {
    margin: 0 auto;
}

#shot-video-nav {
    display: inline-block;
    padding: 5px 0;

    .shot {
        width: 24px;
        height: 24px;
    }
}

#session-summary {
    border-bottom: 2px solid $primary-color;
    border-top: 2px solid $primary-color;
    margin-bottom: 10px;
    margin-right: 0;
    background-color: #eeeeee;

    label {
        margin-top: 8px;
    }
}

#leaderboards {
    .your-rank {
        font-size: 16px;
        padding: 10px 5px;
        display: block;
    }

    table.leaderboard {
        .performance, .rank {
            font-weight: bold;
            background-color: #eee;
        }

        tr.success {
            .performance, .rank {
                background-color: #cde0cd;
            }
        }
    }
}

.tropies-list {
    .col-xs-4 {
        padding-right: 5px;
        padding-left: 5px;
    }

    .trophy {
        text-align: center;
        padding: 10px;
        font-size: 13px;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin: 10px 0;

        img {
            max-width: 100%;
            height: auto;
            padding: 0 10px;
        }
        img.trophy-lock {
            // max-width: 50%;
            margin-top: -100%;
            pointer-events: none;

            @media (max-width: 768px) {
                // This looks better if we tweak it for phones
                max-width: 75%;
                margin-top: -115%;
            }
        }

        .trophy-name {
            font-weight: bold;
            padding-top: 10px;
        }

        .achieved-date {
            font-weight: normal;
            font-style: italic;
        }

        .popover {
            width: 200px;
        }
    }
    .unawarded {
        opacity: .2;
    }

    @extend %progress;
    .progress {
        margin-top: 5px;

        .progress-bar-empty {
            color: black;
            margin-left: 5px;
        }
    }
}

/******************************************************************************
* Messages / Alerts
******************************************************************************/
.player-messages .unread, .player-alerts .unread {
    display: inline-block;
    background-color: $primary-color;
    width: 7px;
    height: 7px;
    border-radius: 5px;
}

.player-alerts {
    @extend %progress;
}

.alert-navbar {
    li.disabled:first-child > a {
        &:focus, &:hover {
            cursor: auto;
        }
    }
}

/******************************************************************************
* Goals
******************************************************************************/

.goals-list {
    .goal {
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        padding: 9px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
        margin-bottom: 10px;

        h2 {
            font-size: 16px;
            font-weight: bold;
            margin: 5px 0;

            .label {
                display: inline-block;
                margin-right: 10px;
                font-size: 100%;
            }
        }

        .row {
            margin-bottom: 5px;
        }

        .progress {
            background-color: white;
            width: 300px;
            margin-bottom: 5px;
        }

        &.expired {
            color: #999999;
            h2 .label {
                background-color: #999999;
            }
        }
    }
}

.player-goals {
    @extend %progress;
}

/******************************************************************************
* Drills
******************************************************************************/

.player-drills {
    .nav-tabs {
        border-bottom: none;

        li {
            border-bottom: 1px solid #ddd;
        }
        li.active {
            border: 1px solid #ddd;
            border-bottom: none;

            a {
                border-bottom: none;
            }
        }
        a {
            font-size: 20px;
            border: none;
        }
    }
}

.drills-list {

    .drill {
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        padding: 9px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
        margin-bottom: 10px;

        // These are modified from .media styles, to fit nicely but not have hidden overflow (which messes up tooltips)
        .drill-chart {
            display: table-cell;
            vertical-align: top;
            width: 114px;
            text-align: center;

            .btn {
                margin-top: 4px;
            }
        }
        .drill-desc {
            display: table-cell;
            vertical-align: top;
            width: 10000px;
            padding-left: 5px;

            h4 {
                margin-top: 0;
            }
        }
    }

    .drill-shots {
        height: 72px; // Give the element spacing before it renders, so it doesn't shift around after
    }
}

.drill-view-shots, .workout-view-drills {
    .handle {
        cursor: move;
    }
}

.drills-navbar {
    li a {
        // Give a little more leeway for mobile (10px width vs 20px)
        padding: 10px 10px;
    }
}

/******************************************************************************
* Telestration
******************************************************************************/
.telestration {

    @media (min-width: 1200px) {
        .container {
            // Override custom theme to give us more room to work with
            widtH: 1170px !important;
        }
    }

    #tt-tools, #tt-comment, #tt-playback-comment {
        display: none;
    }
    #tt-help {}

    #canvas-holder {
        position: relative;
    }

    .annotation-tools {

        hr {
            margin-top: 10px;
            margin-bottom: 10px;
            border-top: 1px solid #dbdbdb;
        }

        .tool {
            width: 41px;
            height: 34px;

            .tool-color {
                display: block;
                width: 16px;
                height: 16px;

                &.yellow {
                    background-color: yellow;
                }
                &.pink {
                    background-color: #ff00cf;
                }
                &.aqua {
                    background-color: aqua;
                }
                &.red {
                    background-color: red;
                }
                &.black {
                    background-color: black;
                }
            }

            .tool-shape {
                display: block;
                background-color: black;

                &.one {
                    height: 4px;
                    width: 4px;
                    border-radius: 2px;
                    margin-left: 6px;
                }
                &.two {
                    height: 6px;
                    width: 6px;
                    border-radius: 4px;
                    margin-left: 6px;
                }
                &.three {
                    height: 8px;
                    width: 8px;
                    border-radius: 6px;
                    margin-left: 4px;
                }
                &.four {
                    height: 10px;
                    width: 10px;
                    border-radius: 8px;
                    margin-left: 4px;
                }
            }
        }

    }

    .text-tools {
        display: none;
    }
}

/******************************************************************************
* Manufacturing screens
******************************************************************************/

body.manufacturing {
    .scan-step {
        text-align: center;

        img {
            margin-bottom: 10px;
        }

        input.barcode {
            margin: 0 auto;
            width: 150px;
            font-size: 20px;
            font-weight: bold;
            text-align: center;

            &:focus {
                background-color: #dff0d8;
            }
        }

        .finish-row {
            margin-top: 10px;
        }

        .or-scan {
            padding-top: 10px;
        }

        .scan-finish {
            margin-top: 5px;
        }

        .scan-results {
            margin-top: 10px;
            font-weight: bold;
        }
    }
}

/******************************************************************************
* Training Vault (formerly known as Preview) screens
******************************************************************************/

$training-font: 'Bebas Neue', Haettenschweiler, 'Arial Narrow Bold', sans-serif;

// Use our own version of the nav so centering works nicely
.training-nav {
    height: 70px;

    img {
        margin: 15px;
    }
}
#training-nav-title {
    font-size: 36px;
    font-family: $training-font;
    color: white;
    margin-top: 10px;
}

// The training auth page stuff
#training-container {
    text-align: center;
    margin-top: 20px;

    #training-header {
        position: relative;
        width: 100%;
        margin-top: -20px;
        background: url(../img/preview/header-bg.png) no-repeat center center;
        background-size: auto;
        background-size: cover;

        #training-header-content {
            padding-top: 50px;
            color: white;

            h1 {
                font-size: 48px;
                font-family: $training-font;
                margin-bottom: 50px;
                sup {
                    font-size: 16px;
                    top: -2em;
                }
            }
            h2 {
                font-size: 32px;
                font-weight: bold;
            }
            h3 {
                font-size: 24px;
                font-weight: bold;
                margin-top: 10px;
            }

            p {
                margin-top: 40px;
            }
        }
    }

    #training-body {
        color: black;
        margin: 20px 0;
    }

    #training-tips {
        margin-top: 50px;

        h2 {
            font-family: $training-font;
        }
    }

    .training-field {
        margin: 10px 0;

        input {
            color: #444;
            font-size: 18px;
            padding: 5px 10px;
            width: 350px;
        }
    }

    button.training-button {
        background-color: #EB3524;
        color: white;
        width: 250px;
        font-family: $training-font;
        text-transform: uppercase;
    }

    #signup-modal {
        text-align: left;
    }
}

// For the main vault screen, which is a modified version of workout list
.training-vault {
    h1, h2, h3, h4 {
        color: black;
    }    
    .training-top-section {
        h2, h3 {
            font-family: $training-font;
        }
        h4 {
            font-weight: bold;
        }
        background: url(../img/preview/vault.png) no-repeat center 35%;
        @media (max-width: 720px) {
            background-position: center 20%;
            background-size: contain;
        }
    }
    .training-top-banner {
        background: url(../img/preview/swish-bg-banner.png) no-repeat center center;
        margin-bottom: 20px;

        h2 {
            font-size: 24px;
            color: white;
        }

        button, a.btn {
            margin: 10px 0;
            background-color: #EB3524;
            font-family: $training-font;
            font-size: 24px;
            text-transform: uppercase;
            padding: 5px 15px;
            border: none;
            border-radius: 5px;
        }
    }
    .training-vault-tips {
        margin: 60px 0;
    }
}
